import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@app/shared/models/user';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  resourceName = 'users';

  constructor(private http: HttpClient) {}

  findById(id: string): Observable<User> {
    return this.http.get<User>(
      `${environment.API_URL}/${this.resourceName}/${id}`
    );
  }

  updateUserInfo(
    id: string,
    info: { firstName: string; lastName: string }
  ): Observable<User> {
    return this.http.put<User>(
      `${environment.API_URL}/${this.resourceName}/${id}`,
      info
    );
  }
}
