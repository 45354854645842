<h1 mat-dialog-title>{{ post ? "Edit" : "Add New" }} Post</h1>
<form [formGroup]="postForm" (ngSubmit)="post ? updatePost() : createNewPost()">
  <div mat-dialog-content>
    <p>What's on your mind?</p>
    <mat-form-field appearance="fill">
      <mat-label>Textarea</mat-label>
      <textarea matInput formControlName="text"></textarea>
      <mat-error>Required</mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button type="button" (click)="onNoClick()" color="warn">
      Cancel
    </button>
    <button mat-button type="submit" cdkFocusInitial color="primary">
      {{ post ? "Update" : "Send" }}
      <mat-icon class="icon-btn">{{ post ? "save" : "send" }}</mat-icon>
    </button>
  </div>
</form>
