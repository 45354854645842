import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Logout } from '@app/shared/store/auth/auth.action';
import { AuthState } from '@app/shared/store/auth/auth.state';
import { Store } from '@ngxs/store';
import { AboutDialogComponent } from '../dialogs/about-dialog/about-dialog.component';

@Component({
  selector: 'evd-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  hide = false;
  hideList = ['/login', '/forgot-password'];

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        const loggedIn = this.store.selectSnapshot(AuthState.isAuthenticated);
        this.hide = this.hideList.includes(this.router.url) || !loggedIn;

        // console.log('loggedIn', loggedIn);
        // console.log('hide', this.hide);
      }

      // NavigationStart
      // NavigationEnd
      // NavigationCancel
      // NavigationError
      // RoutesRecognized
    });
  }

  logout() {
    this.store.dispatch(new Logout());
  }

  openAboutDialog() {
    const dialogRef = this.dialog.open(AboutDialogComponent);
    dialogRef.afterClosed().subscribe();
  }
}
