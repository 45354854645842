export class Post {
  id: string;
  dateCreated: Date;
  dateModified: Date;

  text: string;

  displayName?: string;
  imgUrl?: string;
  userImgUrl?: string;

  constructor(
    id: string,
    dateCreated: Date,
    dateModified: Date,
    text: string,
    displayName: string,
    userImgUrl: string,
    imgUrl: string
  ) {
    this.id = id;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.text = text;
    this.displayName = displayName;
    this.userImgUrl = userImgUrl;
    this.imgUrl = imgUrl;
  }
}
