import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { SetAuthentication } from '@app/shared/store/auth/auth.action';
import { FetchUser } from '@app/shared/store/user/user.action';
import { Store } from '@ngxs/store';
import { NotificationsService } from 'angular2-notifications';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private auth: AngularFireAuth,
    private notificationService: NotificationsService,
    private store: Store
  ) {
    this.auth.onAuthStateChanged((user) => {
      if (user) {
        this.store.dispatch(new FetchUser({ id: user.uid }));
        this.store.dispatch(new SetAuthentication(user));
        // console.log('user', user);
      } else {
        // console.log('user NULL');
        this.store.dispatch(new SetAuthentication(null));
      }
    });
  }

  login(email: string, password: string): Observable<any> {
    const temp = this.auth.signInWithEmailAndPassword(email, password);
    return from(temp);
  }

  logout(): Observable<void> {
    return from(this.auth.signOut());
  }

  async getToken(): Promise<Promise<string> | undefined> {
    const currentUser = await this.auth.currentUser;
    return currentUser?.getIdToken();
  }

  signUp(email: string, password: string): void {
    this.auth.createUserWithEmailAndPassword(email, password);
  }

  sendPasswordResetEmail(email: string): void {
    this.auth
      .sendPasswordResetEmail(email)
      .then(() => {
        // Email sent.
        this.notificationService.success(
          'Email Sent',
          'You will receive an email in a moment.'
        );
      })
      .catch((err) => {
        // An error happened.
        this.notificationService.error(err.code, err.message);
      });
  }

  async resetPassword(password: string) {
    const currentUser = await this.auth.currentUser;
    return currentUser
      ?.updatePassword(password)
      .then(() => {
        // Email sent.
        this.notificationService.success(
          'Password Changed',
          'Be sure to save it in a safe place.'
        );
      })
      .catch((err) => {
        // An error happened.
        this.notificationService.error(err.code, err.message);
      });
  }
}
