import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'evd-not-found',
  templateUrl: './not-found.page.html',
  styleUrls: ['./not-found.page.scss'],
})
export class NotFoundPage implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
