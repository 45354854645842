import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PostDialogComponent } from '@app/feature/components/dialogs/post-dialog/post-dialog.component';
import { Post } from '@app/shared/models/post';
import { DeletePost, FetchAllPosts } from '@app/shared/store/post/post.action';
import { PostState } from '@app/shared/store/post/post.state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'evd-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnInit {
  //
  @Select(PostState.getPostList) posts$: Observable<Post[]>;

  constructor(private dialog: MatDialog, private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(new FetchAllPosts());
  }

  openAddPostDialog() {
    const dialogRef = this.dialog.open(PostDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      // console.log(`Dialog result: ${result}`);
    });
  }

  follow() {
    console.log('follow');
  }

  like() {
    console.log('like');
  }

  share() {
    console.log('share');
  }

  comment() {
    console.log('comment');
  }

  editPost(post: Post) {
    console.log('editPost', post);

    const dialogRef = this.dialog.open(PostDialogComponent, {
      data: { post },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  deletePost(id: string) {
    console.log('deletePost', id);

    this.store.dispatch(new DeletePost(id));
  }

  reportPost() {
    console.log('reportPost');
  }
}
