import { Injectable } from '@angular/core';
import { UserService } from '@app/core/services/user/user.service';
import { State, Selector, Action, StateContext, StateToken } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { FetchUser, UpdateUser, UserStateModel } from './user.action';
import { User } from '@app/shared/models/user';
import { NotificationsService } from 'angular2-notifications';

const USER_STATE_TOKEN = new StateToken<UserStateModel[]>('user');

@State<UserStateModel>({
  name: USER_STATE_TOKEN,
})
@Injectable()
export class UserState {
  @Selector()
  static getEntity(state: UserStateModel): User {
    return state.entity;
  }

  constructor(
    private userService: UserService,
    private notificationService: NotificationsService
  ) {}

  @Action(FetchUser)
  fetch({ setState }: StateContext<UserStateModel>, action: FetchUser) {
    return this.userService.findById(action.payload.id).pipe(
      tap((result) => {
        if (result) {
          setState({
            entity: result,
          });
        } else {
          setState({
            entity: result,
          });
        }
      })
    );
  }

  @Action(UpdateUser)
  update(
    { patchState }: StateContext<UserStateModel>,
    { payload, id }: UpdateUser
  ) {
    return this.userService.updateUserInfo(id, payload).pipe(
      tap((result) => {
        patchState({
          entity: result,
        });

        this.notificationService.success(
          'Success',
          'User information updated.'
        );
      })
    );
  }
}
