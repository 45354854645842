import { Component, Inject, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Post } from '@app/shared/models/post';
import { AddPost, UpdatePost } from '@app/shared/store/post/post.action';
import { Store } from '@ngxs/store';

@Component({
  selector: 'evd-post-dialog',
  templateUrl: './post-dialog.component.html',
  styleUrls: ['./post-dialog.component.scss'],
})
export class PostDialogComponent implements OnInit {
  post: Post;

  postForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<PostDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { post: Post },
    private formBuilder: FormBuilder,
    private store: Store
  ) {
    console.log('constructor :: data', this.data);

    this.post = this.data?.post;

    this.postForm = this.formBuilder.group({
      text: [this.post ? this.post.text : '', Validators.required],
    });

    console.log('post', this.post);

    console.log('postForm', this.postForm);
  }

  ngOnInit(): void {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  createNewPost() {
    // console.log(this.postForm.value);

    if (this.postForm.valid) {
      const post = new Post(
        '',
        new Date(),
        new Date(),
        this.postForm.controls['text'].value,
        '',
        '',
        ''
      );

      this.store.dispatch(new AddPost(post)).subscribe((val) => {
        console.log('val', val);
        this.dialogRef.close();
      });
    } else {
      this.postForm.markAsDirty;
    }
  }

  updatePost() {
    if (this.postForm.valid) {
      const newPost = Object.assign({}, this.post);

      newPost.text = this.postForm.controls['text'].value;

      delete newPost.displayName;
      delete newPost.imgUrl;
      delete newPost.userImgUrl;

      console.log('updatePost', this.post);
      console.log(
        "this.postForm.controls['text'].value",
        this.postForm.controls['text'].value
      );
      console.log('newPost', newPost);

      this.store
        .dispatch(new UpdatePost(newPost, newPost.id))
        .subscribe((val) => {
          console.log('val', val);
          this.dialogRef.close();
        });
    } else {
      this.postForm.markAsDirty;
    }
  }
}
