import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { NgxsModule } from '@ngxs/store';
import { environment } from '@env/environment';
import { AuthState } from './store/auth/auth.state';
import { PostState } from './store/post/post.state';
import { UserState } from './store/user/user.state';

const mods = [MaterialModule];
const pipes = [DateAgoPipe];

@NgModule({
  declarations: [DateAgoPipe],
  imports: [
    CommonModule,
    mods,
    SimpleNotificationsModule.forRoot(),
    NgxsModule.forRoot([AuthState, UserState, PostState], {
      developmentMode: !environment.production,
    }),
  ],
  exports: [mods, pipes, SimpleNotificationsModule],
})
export class SharedModule {}
