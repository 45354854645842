<div style="display: flex; justify-content: space-between; padding: 20px">
  <div>
    <div>
      <form [formGroup]="userForm">
        <mat-form-field appearance="fill" style="margin-right: 10px">
          <mat-label>First Name</mat-label>
          <input matInput formControlName="firstName" />
          <mat-error>Required</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Last Name</mat-label>
          <input matInput formControlName="lastName" />
          <mat-error>Required</mat-error>
        </mat-form-field>
      </form>

      <button
        mat-raised-button
        color="primary"
        style="margin-top: 8px"
        (click)="update()"
        [disabled]="!userForm.valid"
      >
        Update<mat-icon class="icon-btn">check</mat-icon>
      </button>
    </div>

    <div style="padding-top: 30px">
      <form [formGroup]="passwordForm">
        <mat-form-field appearance="fill">
          <mat-label>Change Password</mat-label>
          <input matInput formControlName="password" type="password" />
          <mat-error
            *ngIf="passwordForm.controls.password.hasError('minlength')"
            >Must be at least 8 characters</mat-error
          >
          <mat-error *ngIf="passwordForm.controls.password.hasError('required')"
            >Required</mat-error
          >
        </mat-form-field>
      </form>

      <button
        mat-raised-button
        color="accent"
        style="margin-top: 8px"
        (click)="changePassword()"
        [disabled]="!passwordForm.valid"
      >
        Change Password<mat-icon class="icon-btn">lock</mat-icon>
      </button>
    </div>
  </div>

  <div>
    <button mat-raised-button color="primary" (click)="getToken()">
      Generate Token<mat-icon class="icon-btn">vpn_key</mat-icon>
    </button>
  </div>
</div>
