import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordPage } from './feature/pages/auth/forgot-password/forgot-password.page';
import { LoginPage } from './feature/pages/auth/login/login.page';
import { NoAccessPage } from './feature/pages/error/no-access/no-access.page';
import { NotFoundPage } from './feature/pages/error/not-found/not-found.page';
import { HomePage } from './feature/pages/home/home.page';
import { ProfilePage } from './feature/pages/profile/profile.page';

import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
} from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    // loadChildren: () =>
    //   import('./feature/pages/auth/login/login.module').then(
    //     (m) => m.LoginModule
    //   ),
    component: HomePage,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'login',
    // loadChildren: () =>
    //   import('./feature/pages/auth/login/login.module').then(
    //     (m) => m.LoginModule
    //   ),
    component: LoginPage,
  },
  {
    path: 'forgot-password',
    // loadChildren: () =>
    //   import('./feature/pages/auth/login/login.module').then(
    //     (m) => m.LoginModule
    //   ),
    component: ForgotPasswordPage,
  },
  {
    path: 'profile',
    // loadChildren: () =>
    //   import('./feature/pages/auth/login/login.module').then(
    //     (m) => m.LoginModule
    //   ),
    component: ProfilePage,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'no-access',
    // loadChildren: () =>
    //   import('./feature/pages/auth/login/login.module').then(
    //     (m) => m.LoginModule
    //   ),
    component: NoAccessPage,
  },
  {
    path: '**',
    // loadChildren: () =>
    //   import('./feature/pages/auth/login/login.module').then(
    //     (m) => m.LoginModule
    //   ),
    component: NotFoundPage,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
