// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyC3KoSfPDgW0f94mUuc3QU7Dtj-1VyXJ4I',
    authDomain: 'everydish-dev.firebaseapp.com',
    databaseURL: 'https://everydish-dev.firebaseio.com',
    projectId: 'everydish-dev',
    storageBucket: 'everydish-dev.appspot.com',
    messagingSenderId: '430440373857',
    appId: '1:430440373857:web:441187965175cc18321420',
    measurementId: 'G-BFXC7TZ8XB',
  },
  envName: 'DEV',
  COMMIT_SHORT_SHA: '2e8b32a',
  BUILD_NUMBER: '16',
  API_URL: 'https://everydish-dev.herokuapp.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
