import { Component, OnInit } from '@angular/core';
import { version } from '../../../../../../package.json';
import { environment } from '@env/environment';

@Component({
  selector: 'evd-about-dialog',
  templateUrl: './about-dialog.component.html',
  styleUrls: ['./about-dialog.component.scss'],
})
export class AboutDialogComponent implements OnInit {
  version = '';
  build = `build: ${environment.BUILD_NUMBER}`;
  commit = `commit: ${environment.COMMIT_SHORT_SHA}`;

  constructor() {}

  ngOnInit(): void {
    const env = environment.production ? '' : `-${environment.envName}`;
    this.version = `Version ${version}${env}`;
  }
}
