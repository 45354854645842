import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'evd-no-access',
  templateUrl: './no-access.page.html',
  styleUrls: ['./no-access.page.scss'],
})
export class NoAccessPage implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
