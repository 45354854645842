import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth/auth.service';
import { State, Selector, Action, StateContext, StateToken } from '@ngxs/store';
import { NotificationsService } from 'angular2-notifications';
import { empty } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  AuthStateModel,
  Login,
  Logout,
  SetAuthentication,
} from './auth.action';

const AUTH_STATE_TOKEN = new StateToken<AuthStateModel[]>('auth');

@State<AuthStateModel>({
  name: AUTH_STATE_TOKEN,
  defaults: {
    isAuthenticated: false,
  },
})
@Injectable()
export class AuthState {
  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return !!state.isAuthenticated;
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private notificationService: NotificationsService
  ) {}

  @Action(Login)
  login(ctx: StateContext<AuthStateModel>, action: Login) {
    return this.authService
      .login(action.payload.email, action.payload.password)
      .pipe(
        tap((result) => {
          this._setAuthentication(ctx, result);
        }),
        catchError((err) => {
          this.notificationService.error(err.code, err.message);
          return empty();
        })
      );
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>) {
    const state = ctx.getState();
    return this.authService.logout().pipe(
      tap(() => {
        ctx.setState({
          isAuthenticated: false,
        });

        this.router.navigate(['/login']);
      })
    );
  }

  @Action(SetAuthentication)
  setAuthentication(
    ctx: StateContext<AuthStateModel>,
    action: SetAuthentication
  ) {
    const state = ctx.getState();
    this._setAuthentication(ctx, action.payload);
  }

  private _setAuthentication(ctx: StateContext<AuthStateModel>, result: any) {
    ctx.patchState({
      isAuthenticated: !!result,
    });
  }
}
