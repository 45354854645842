import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { version } from '../../../../../../package.json';
import { environment } from '@env/environment';
import { Store } from '@ngxs/store';
import { Login } from '@app/shared/store/auth/auth.action';

@Component({
  selector: 'evd-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
  version = '';
  build = `build: ${environment.BUILD_NUMBER}`;
  commit = `commit: ${environment.COMMIT_SHORT_SHA}`;

  loginForm = this.formBuilder.group({
    email: ['', Validators.required],
    password: ['', Validators.required],
  });

  registerForm = this.formBuilder.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', Validators.required],
    password: ['', Validators.required],
    confirmPassword: ['', Validators.required],
  });

  constructor(private formBuilder: FormBuilder, private store: Store) {}

  ngOnInit(): void {
    const env = environment.production ? '' : `-${environment.envName}`;
    this.version = `Version ${version}${env}`;
  }

  onLogin() {
    this.store.dispatch(
      new Login({
        email: this.loginForm.value.email,
        password: this.loginForm.value.password,
      })
    );
  }

  onRegister() {
    console.log('register value', this.registerForm.value); // { first: '', last: '' }
    console.log('register valid', this.registerForm.valid); // false
  }
}
