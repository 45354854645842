import { Post } from '@app/shared/models/post';

export interface PostStateModel {
  posts: Post[];
}

export class AddPost {
  static readonly type = '[Post] Add';

  constructor(public payload: Post) {}
}

export class FetchAllPosts {
  static readonly type = '[Post] Fetch All Posts';
}

export class UpdatePost {
  static readonly type = '[Post] Update';

  constructor(public payload: Post, public id: string) {}
}

export class DeletePost {
  static readonly type = '[Post] Delete';

  constructor(public id: string) {}
}

// export class SetSelectedPost {
//   static readonly type = '[Post] Set';

//   constructor(public payload: Post) {}
// }
