export interface AuthStateModel {
  isAuthenticated: boolean;
}

export class Login {
  static readonly type = '[Auth] Login';
  constructor(public payload: { email: string; password: string }) {}
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class SetAuthentication {
  static readonly type = '[Auth] SetAuthentication';
  constructor(public payload: any) {}
}
