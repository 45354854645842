import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext, StateToken } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  AddPost,
  DeletePost,
  FetchAllPosts,
  PostStateModel,
  UpdatePost,
} from './post.action';
import { NotificationsService } from 'angular2-notifications';
import { Post } from '@app/shared/models/post';
import { PostService } from '@app/core/services/post/post.service';

const POST_STATE_TOKEN = new StateToken<PostStateModel[]>('post');

@State<PostStateModel>({
  name: POST_STATE_TOKEN,
  defaults: {
    posts: [],
  },
})
@Injectable()
export class PostState {
  @Selector()
  static getPostList(state: PostStateModel): Post[] {
    return state.posts;
  }

  constructor(
    private postService: PostService,
    private notificationService: NotificationsService
  ) {}

  @Action(FetchAllPosts)
  getPosts({ getState, setState }: StateContext<PostStateModel>) {
    return this.postService.getAll().pipe(
      tap((result) => {
        const state = getState();
        setState({
          ...state,
          posts: result,
        });
      })
    );
  }

  @Action(AddPost)
  addPost(
    { getState, patchState }: StateContext<PostStateModel>,
    { payload }: AddPost
  ) {
    console.log('payload', payload);

    return this.postService.create(payload).pipe(
      tap((result) => {
        const state = getState();

        console.log('result', result);

        // TODO: remove later
        // if even number
        if ((state.posts.length + 1) % 2 === 0) {
          result.displayName = 'Shiba Inu';
          result.userImgUrl =
            'https://material.angular.io/assets/img/examples/shiba1.jpg';
          result.imgUrl =
            'https://material.angular.io/assets/img/examples/shiba2.jpg';
        } else {
          result.displayName = 'Test User';
          result.userImgUrl = 'https://via.placeholder.com/510x600';
          result.imgUrl = 'https://via.placeholder.com/750x600';
        }

        patchState({
          posts: [...state.posts, result],
        });
      })
    );
  }

  @Action(UpdatePost)
  updatePost(
    { getState, setState }: StateContext<PostStateModel>,
    { payload, id }: UpdatePost
  ) {
    console.log('payload', payload);

    return this.postService.update(payload, id).pipe(
      tap((result) => {
        const state = getState();

        console.log('result', result);

        // TODO: remove later
        // if even number
        if ((state.posts.length + 1) % 2 === 0) {
          result.displayName = 'Shiba Inu';
          result.userImgUrl =
            'https://material.angular.io/assets/img/examples/shiba1.jpg';
          result.imgUrl =
            'https://material.angular.io/assets/img/examples/shiba2.jpg';
        } else {
          result.displayName = 'Test User';
          result.userImgUrl = 'https://via.placeholder.com/510x600';
          result.imgUrl = 'https://via.placeholder.com/750x600';
        }

        const postList = [...state.posts];
        const postIndex = postList.findIndex((item) => item.id === id);
        postList[postIndex] = result;
        setState({
          ...state,
          posts: postList,
        });
      })
    );
  }

  @Action(DeletePost)
  deletePost(
    { getState, setState }: StateContext<PostStateModel>,
    { id }: DeletePost
  ) {
    return this.postService.delete(id).pipe(
      tap((result) => {
        const state = getState();

        console.log('result', result);

        // TODO: remove later
        // if even number
        if ((state.posts.length + 1) % 2 === 0) {
          result.displayName = 'Shiba Inu';
          result.userImgUrl =
            'https://material.angular.io/assets/img/examples/shiba1.jpg';
          result.imgUrl =
            'https://material.angular.io/assets/img/examples/shiba2.jpg';
        } else {
          result.displayName = 'Test User';
          result.userImgUrl = 'https://via.placeholder.com/510x600';
          result.imgUrl = 'https://via.placeholder.com/750x600';
        }

        const filteredArray = state.posts.filter((item) => item.id !== id);
        setState({
          ...state,
          posts: filteredArray,
        });
      })
    );
  }

  // @Action(SetSelectedPost)
  // setSelectedPostId({getState, setState}: StateContext<PostStateModel>, {payload}: SetSelectedPost) {
  //     const state = getState();
  //     setState({
  //         ...state,
  //         selectedPost: payload
  //     });
  // }
}
