import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { AuthService } from '@app/core/services/auth/auth.service';

@Component({
  selector: 'evd-forgot-password',
  templateUrl: './forgot-password.page.html',
  styleUrls: ['./forgot-password.page.scss'],
})
export class ForgotPasswordPage implements OnInit {
  resetPasswordForm = this.formBuilder.group({
    email: ['', Validators.required],
  });

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService
  ) {}

  ngOnInit(): void {}

  onResetPassword(): void {
    if (this.resetPasswordForm.valid) {
      this.authService.sendPasswordResetEmail(
        this.resetPasswordForm.value.email
      );
    }
  }
}
