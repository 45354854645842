import { User } from '@app/shared/models/user';

export interface UserStateModel {
  entity: User;
}

export class FetchUser {
  static readonly type = '[User] Fetch';
  constructor(public payload: { id: string }) {}
}

export class UpdateUser {
  static readonly type = '[User] Update';

  constructor(public payload: User, public id: string) {}
}
