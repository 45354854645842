import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  MinLengthValidator,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@app/core/services/auth/auth.service';
import { TokenDialogComponent } from '@app/feature/components/dialogs/token-dialog/token-dialog.component';
import { User } from '@app/shared/models/user';
import { UpdateUser } from '@app/shared/store/user/user.action';
import { UserState } from '@app/shared/store/user/user.state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'evd-profile',
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss'],
})
export class ProfilePage implements OnInit {
  @Select(UserState.getEntity)
  user$: Observable<User>;
  user: User;

  userForm = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
  });

  passwordForm = new FormGroup({
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
  });

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.user$.subscribe((user: User) => {
      this.user = user;

      if (user) {
        this.userForm.patchValue({
          firstName: user.firstName,
          lastName: user.lastName,
        });
      }
    });
  }

  getToken(): void {
    this.authService.getToken()?.then((token) => {
      const dialogRef = this.dialog.open(TokenDialogComponent, {
        data: { token },
      });

      dialogRef.afterClosed().subscribe();
    });
  }

  update() {
    if (this.userForm.valid) {
      this.store.dispatch(new UpdateUser(this.userForm.value, this.user.id));
    }
  }

  changePassword() {
    if (this.passwordForm.valid) {
      this.authService.resetPassword(
        this.passwordForm.controls['password'].value
      );
    }
  }
}
