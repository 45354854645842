<div class="center">
  <h3>Trouble Logging In?</h3>
  <p>
    Enter your email and we'll send you a link to get back into your account.
  </p>
</div>

<form [formGroup]="resetPasswordForm" (ngSubmit)="onResetPassword()">
  <mat-form-field appearance="fill">
    <mat-label>Email</mat-label>
    <input matInput formControlName="email" />
    <mat-error>Required</mat-error>
  </mat-form-field>

  <button mat-raised-button color="primary" type="submit">
    Send Login Link
  </button>

  <div class="center forgot-btn">
    <a mat-button routerLink="/login" color="primary">Back To Login</a>
  </div>
</form>
