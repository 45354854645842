<button mat-mini-fab color="primary" (click)="openAddPostDialog()">
  <mat-icon>add</mat-icon>
</button>

<mat-card class="post-card" *ngFor="let post of posts$ | async">
  <mat-card-header>
    <div
      mat-card-avatar
      class="post-header-image"
      [ngStyle]="{'background-image': 'url(' + post.userImgUrl + ')'}"
    ></div>
    <mat-card-title>{{ post.displayName }}</mat-card-title>
    <mat-card-subtitle>{{ post.dateCreated | dateAgo }}</mat-card-subtitle>

    <span class="spacer"></span>

    <button
      mat-stroked-button
      color="primary"
      class="follow-btn"
      (click)="follow()"
    >
      FOLLOW
    </button>

    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="editPost(post)">Edit</button>
      <button mat-menu-item (click)="reportPost()">Report</button>
      <button mat-menu-item (click)="deletePost(post.id)" style="color: red">
        Delete
      </button>
    </mat-menu>
  </mat-card-header>

  <img mat-card-image src="{{ post.imgUrl }}" alt="Photo of a User" />

  <mat-card-content>
    <p>{{ post.text }}</p>
  </mat-card-content>

  <mat-card-actions>
    <div style="display: flex">
      <span class="spacer"></span>

      <button mat-icon-button (click)="like()">
        <mat-icon>favorite</mat-icon>
      </button>
      <button mat-icon-button (click)="share()">
        <mat-icon>share</mat-icon>
      </button>

      <button mat-icon-button (click)="comment()">
        <mat-icon>comment</mat-icon>
      </button>
    </div>
  </mat-card-actions>
</mat-card>
