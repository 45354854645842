<h1 mat-dialog-title>Token</h1>

<div mat-dialog-content class="wrap">
  <p>{{ data.token }}</p>
</div>

<div mat-dialog-actions>
  <button
    mat-raised-button
    color="primary"
    [cdkCopyToClipboard]="data.token"
    (click)="copy()"
  >
    Copy<mat-icon class="icon-btn">content_copy</mat-icon>
  </button>
</div>
