import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginPage } from './pages/auth/login/login.page';
import { ForgotPasswordPage } from './pages/auth/forgot-password/forgot-password.page';
import { HomePage } from './pages/home/home.page';
import { ProfilePage } from './pages/profile/profile.page';
import { NotFoundPage } from './pages/error/not-found/not-found.page';
import { NoAccessPage } from './pages/error/no-access/no-access.page';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { PostDialogComponent } from './components/dialogs/post-dialog/post-dialog.component';
import { CommentDialogComponent } from './components/dialogs/comment-dialog/comment-dialog.component';
import { TokenDialogComponent } from './components/dialogs/token-dialog/token-dialog.component';
import { AboutDialogComponent } from './components/dialogs/about-dialog/about-dialog.component';

@NgModule({
  declarations: [
    LoginPage,
    HomePage,
    ProfilePage,
    NotFoundPage,
    NoAccessPage,
    ForgotPasswordPage,
    HeaderComponent,
    PostDialogComponent,
    CommentDialogComponent,
    TokenDialogComponent,
    AboutDialogComponent,
  ],
  imports: [CommonModule, SharedModule, RouterModule],
  exports: [HeaderComponent],
})
export class FeatureModule {}
