<mat-toolbar *ngIf="!hide">
  <button mat-button routerLink="">Home</button>
  <button mat-button routerLink="no-access">No Access</button>
  <button mat-button routerLink="FAKE">Fake</button>
  <span class="spacer"></span>
  <button mat-icon-button>
    <mat-icon>notifications_active_outlined</mat-icon>
  </button>
  <button mat-icon-button (click)="openAboutDialog()">
    <mat-icon>info_outlined</mat-icon>
  </button>
  <button mat-button [matMenuTriggerFor]="menu">
    User Name<mat-icon class="acct-icon-btn">account_circle_outlined</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item routerLink="profile">Profile</button>
    <button mat-menu-item (click)="logout()">Logout</button>
  </mat-menu>
</mat-toolbar>
