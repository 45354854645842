import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Post } from '@app/shared/models/post';
import { environment } from '@env/environment';
import { NotificationsService } from 'angular2-notifications';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PostService {
  resourceName = 'posts';

  constructor(
    private notificationService: NotificationsService,
    private http: HttpClient
  ) {}

  create(post: Post) {
    console.log(post);

    return this.http
      .post<Post>(`${environment.API_URL}/${this.resourceName}`, post)
      .pipe(
        tap((val) => {
          console.log('VAL', val);
          this.notificationService.success('Success', 'New post created.');
        }),
        catchError(this.errorHandler)
      );
  }

  getById(id: string): Observable<Post> {
    return this.http
      .get<Post>(`${environment.API_URL}/${this.resourceName}/${id}`)
      .pipe(catchError(this.errorHandler));
  }

  getAll() {
    return this.http.get(`${environment.API_URL}/${this.resourceName}`).pipe(
      map((response) => {
        const data: Post[] = response as Post[];

        data.forEach((val, idx, arr) => {
          let displayName;
          let userImgUrl;
          let imgUrl;

          // TODO: remove later
          // if even number
          if (idx % 2 === 0) {
            displayName = 'Shiba Inu';
            userImgUrl =
              'https://material.angular.io/assets/img/examples/shiba1.jpg';
            imgUrl =
              'https://material.angular.io/assets/img/examples/shiba2.jpg';
          } else {
            displayName = 'Test User';
            userImgUrl = 'https://via.placeholder.com/510x600';
            imgUrl = 'https://via.placeholder.com/750x600';
          }

          arr[idx].displayName = displayName;
          arr[idx].userImgUrl = userImgUrl;
          arr[idx].imgUrl = imgUrl;
        });

        return data;
      }),
      catchError(this.errorHandler)
    );
  }

  update(post: Post, id: string) {
    console.log('id', id);
    console.log('post', post);

    return this.http
      .put<Post>(`${environment.API_URL}/${this.resourceName}/${id}`, post)
      .pipe(
        tap((val) => {
          console.log('VAL', val);
          this.notificationService.success('Success', 'New post created.');
        }),
        catchError(this.errorHandler)
      );
  }

  delete(id: string) {
    console.log(id);

    return this.http
      .delete<Post>(`${environment.API_URL}/${this.resourceName}/${id}`)
      .pipe(
        tap((val) => {
          console.log('VAL', val);
          this.notificationService.success('Success', 'New post created.');
        }),
        catchError(this.errorHandler)
      );
  }

  errorHandler(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => new Error(errorMessage));
  }
}
