<div class="main">
  <div class="title">
    <h1>EveryDish</h1>
    <h6>{{version}}</h6>
    <h6>{{build}}</h6>
    <h6>{{commit}}</h6>
  </div>

  <mat-card class="center-card">
    <mat-tab-group mat-align-tabs="center" class="tab-group">
      <mat-tab label="Log In">
        <div class="login-tab-content">
          <form [formGroup]="loginForm" (ngSubmit)="onLogin()">
            <mat-form-field appearance="fill">
              <mat-label>Email</mat-label>
              <input matInput formControlName="email" />
              <mat-error>Required</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Password</mat-label>
              <input matInput formControlName="password" type="password" />
              <mat-error>Required</mat-error>
            </mat-form-field>

            <button
              mat-raised-button
              type="submit"
              color="primary"
              class="submit-btn"
            >
              Log In
            </button>

            <div class="center forgot-btn">
              <a mat-button routerLink="/forgot-password" color="primary"
                >Forgot your password?</a
              >
            </div>
          </form>
        </div>
      </mat-tab>

      <mat-tab label="Sign Up">
        <div class="login-tab-content">
          <form [formGroup]="registerForm" (ngSubmit)="onRegister()">
            <mat-form-field appearance="fill">
              <mat-label>First Name</mat-label>
              <input matInput formControlName="firstName" />
              <mat-error>Required</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Last Name</mat-label>
              <input matInput formControlName="lastName" />
              <mat-error>Required</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Email</mat-label>
              <input matInput formControlName="email" />
              <mat-error>Required</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Password</mat-label>
              <input matInput formControlName="password" type="password" />
              <mat-error>Required</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Confirm Password</mat-label>
              <input
                matInput
                formControlName="confirmPassword"
                type="password"
              />
              <mat-error>Required</mat-error>
            </mat-form-field>

            <button
              mat-raised-button
              type="submit"
              color="primary"
              class="submit-btn"
              disabled
            >
              Create New Account
            </button>
          </form>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
</div>
